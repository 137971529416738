.agents{
    padding: 2rem 1rem 4rem 1rem;
    display: grid;
    place-items:center;
}
.agents-head{
    text-align: center;
}
.agents-head h2{
    font-size: 2.2rem;
    font-weight: 600;
    padding-top: 2rem;
    color:rgba(0, 0, 0 , 0.8);
}
.agents-head span{
    color:var(--point);
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 2px;
    position: relative;

}
.agents-head span::before{
    position: absolute;
    top: 50%;
    right:-60px;
    content:'';
    width: 50px;
    height: 1px;
    background:var(--point);
}
.agents-head span::after{
    position: absolute;
    top: 50%;
    right:-60px;
    content:'';
    width: 50px;
    height: 1px;
    background:var(--point);
}
.agent-row{
 padding-top: 3rem;
 display: grid;
 grid-template-columns:1fr 1fr 1fr 1fr;
}
.agent-col{
    padding: 0 20px;
}
.single-agent{
    margin-bottom: 30px;
}
.agent-thumb{
    position:relative;
    border-radius:10px;
    overflow:hidden;
}
.agent-thumb img{
   width: 250px;
   height: 270px;
   border-radius: 10px;
   transition:0.3s; 
}
.agent-col:hover .agent-thumb img{
    transform:scale(1.1);
}
.agent-thumb .social-link{
   position: absolute;
   bottom: 0;
   width: 250px;
   background: rgba(0,29,56,0.5);
   border-radius: 10px;
   text-align: center;
   padding: 13px 0;
   transition:0.5s;
   transform:translateY(100%) scale(1.2);
   opacity: 0;
   visibility: hidden;
}
.agent-col:hover .social-link{
    transform:translateY(0%) scale(1);
    opacity: 1;
    visibility: visible;
}
.agent-thumb .social-link ul{
   display: flex;
   align-items: center;
   justify-content: center; 
}
.agent-thumb .social-link li a{
    font-size: 15px;
    color:#C7C7C7;
    margin: 0 6px;
}
.agent-info{
    text-align: center;
}
.agent-info h3{
    margin-top: 1rem;
    font-size: 22px;
    font-weight: 400;
    color:#2C2C2C;
    margin-bottom: 0;
}
.agent-info p{
    font-size: 13px;
    font-weight: 300;
    color:#2C2C2C;
    margin-bottom: 8px;
    margin-top: 6px;
}
@media screen and (max-width:1200px) {
    .agent-col{
        padding: 0 15px;
    }
    .agent-thumb img{
        width: 200px;
        height: 250px;
    }
    .agent-thumb .social-link{
        width: 200px;
    }

    
}
@media screen and (max-width:948px){
    .agent-row{
        grid-template-columns:1fr 1fr ;
    }
    .agent-col{
        padding: 0 1rem 3rem 1rem;
    }
    .agent-thumb img{
        width: 300px;
        height: 300px;
    }
    .agent-thumb .social-link{
        width: 300px;
    }


}
@media screen and (max-width:768px){
   .agents{
     padding: 1rem;
   } 
   .agent-row{
    grid-template-columns:1fr;
   }
   .agent-thumb img{
    width: 450px;
    height: 400px;
}
.agent-thumb .social-link{
    width: 450px;
}


}
@media screen and (max-width:500px){
    .agent-col{
        padding: 0 0 3rem 0;
    }
    .agent-thumb img{
        width: 400px;
        height: 400px;
    }
    .agent-thumb .social-link{
        width: 400px;
    }

}
@media screen and (max-width:440px){
    .agent-thumb img{
        width: 350px;
        height: 400px;
    }
    .agent-thumb .social-link{
        width: 350px;
    }

}
@media screen and (max-width:385px){
    .agent-thumb img{
        width: 300px;
        height: 350px;
    }
    .agent-thumb .social-link{
        width: 300px;
    } 

}
@media screen and (max-width:321px){
    .agent-thumb img{
        width: 270px;
        height: 300px;
    }
    .agent-thumb .social-link{
        width: 270px;
    }

}
@media screen and (max-width:291px){
    .agent-thumb img{
        width: 230px;
        height: 270px;
    }
    .agent-thumb .social-link{
        width: 230px;
    }

}