.navbar{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 9;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 5rem;
    
}
nav>h1{
    color: var(--white);
    font-size: 2rem;
    font-family: 'Nunito', sans-serif;
    letter-spacing: 0.5px;

}
nav>h1>span{
    color:var(--point);
}
.menu{
    display: flex;
}
.menu ul{
    display: flex;
}
.menu ul >li{
    padding: 10px 25px;
}
.menu ul >li>a{
    color: var(--white);
    font-weight: 400;
    letter-spacing: 0.5px;

}
.btn{
    background:var(--point);
    padding: 7px 20px;
    border:2px solid var(--point);
    margin-left: 2rem;
    border-radius:30px;
}
.btn a{
    color: var(--white);
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.5px;
}
.bars{
    display: none;
    font-size: 2rem;
    cursor: pointer;
    color: var(--point);
}

.navbar.active{
    position: fixed;
    width: 100%;
    top: -70px;
    left: 0;
    right: 0;
    z-index: 990;
    box-shadow: 0 3px 16px 0 rgb(0 0 0 / 10%);
    background-color: #2c2a2f;
    transform:translateY(70px);
    transition:transform 1s ease;
}

@media screen and (max-width: 985px){
    .navbar .active{
        display: none;
    }
    nav{
        padding-left: 3rem;
        padding-right: 3rem;
    }
    nav h1{
        font-size: 1.6rem;
    }
    .btn{
        margin: 0;
    }
    .bars{
        display: flex;
    }
    .menu{
        display: block;
        z-index:9999 !important;
        position: absolute;
        left: 3%;
        top: 68px;
        width: 93%;
        background-color: var(--menu);
        transform: translateY(-300px);
        transition:transform 1s ease-in-out visibility 1s;
        padding-bottom: 1rem;
        border-radius:1rem;
        visibility: hidden;
    }
    .mobile-menu{
        visibility:visible;
        transform: translateY(0px);
        transition:transform 1s ease-in-out visibility 1s;

    }
    .menu ul{
        display: block;
    }
    .menu ul>li{
        padding: 17px 3rem;
    }
    .btn{
        padding-left: 3rem;
        border:none;
        background:transparent;
    }
}
@media screen and (max-width: 550px){
    nav{
        padding-left: 2rem;
        padding-right: 2rem;
    }
    .bars{
        font-size: 1.7rem;
    }
}
@media screen and (max-width: 380px){

    nav{
        padding: 0 1rem;
    }
    nav h1{
        font-size: 1.5rem;
    }
    .bars{
        font-size: 1.5rem;
    }
}
