.home{
    display: flex;
    padding-top: 13rem;
    padding-left: 5rem;
    background-image: url("../../img/test.jpg");
    background-size:cover;
    background-repeat: no-repeat;
    background-position:center center;
    width: 100%;
    height: 100vh;
}
.home::before{
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    background:var(--background);
    opacity: .6;
}
.container{
    position: relative;
}
.container h1{
    color:var(--white);
    font-size: 4rem;
    line-height: 1.3;
    margin: 10px 0px 20px;
    font-weight: 600;
    text-transform: capitalize;
    letter-spacing: 1px;
    font-family: 'Nunito', sans-serif;
}
.container p{
    font-weight: 400;
    width: 500px;
    letter-spacing: 1px;
    line-height: 1.7;
    color:var(--white);
}
.link{
    display: flex;
    margin-top: 2rem;
}
.link .explore{
    margin-right: 20px;
    padding: 12px 40px;
    background-color:var(--point);
    border:1px solid var(--point);
    border-radius:2rem 0;
    color:var(--white);
    font-weight: 400;
    letter-spacing: 0.5px;
    transition:1s ease-in-out;
}
.link .explore:hover{
    color:var(--point);
    background:transparent;
}
.link .contact{
    padding:12px 40px;
    border-radius:2rem 0;
    border:1px solid var(--point);
    font-weight: 400;
    letter-spacing: 0.5px;
    color:var(--point);
    transition:1s ease-in-out;
}
.link .contact:hover{
    background-color:var(--point);
    color:var(--white);
}
@media screen and (max-width:985px) {
    .home{
        padding-left: 3rem;
    }
    .container h1{
        font-size: 3.5rem;
    }
    
}
@media screen and (max-width:768px) {
    .home{
        padding-left: 2rem;
    }
    .container h1{
        font-size: 3.2rem;
    }
    .container p{
        width: 400px;
        font-size: 15px;
    }
    .link .explore{
        padding: 10px 25px;
    }
    .link .contact{
        padding: 10px 25px;
    }
}
@media screen and (max-width:480px) {
    .home{
        padding: 11rem 1rem 0 2rem;
        height: 80vh;
    }
    .home::before{
        bottom: 20%;
    }
    .container h1{
        font-size: 3.2rem;
    }
    .container p{
        font-size: 14px;
        width:fit-content;
    }
    .link a{
        font-size: 14px;
    }
    .link .explore{
        padding: 10px 25px;
    }
    .link .contact{
        padding: 10px 25px;
    }
}
@media screen and (max-width:380px){
   .home{
     padding-top: 9rem;
   }
   .container h1{
     font-size:2.3rem;
   }
   .link .contact{
    padding: 10px 17px;
   }
   .link .explore{
    padding: 10px 17px;
    margin-top: 10px;
    }
}