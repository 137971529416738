.accordion{
    padding: 6rem 0 5rem 0;
    display: grid;
    place-items:center;

}
.accordion-row{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    max-width: 1140px;
}
.left{
    padding-right: 4rem;
    width: 50%;
}
.left h3{
    font-size: 46px;
    font-weight: 300;
    color: #414243;
    margin-bottom: 1rem;
}
.left-content{
    display: flex;
    justify-content: center;
    border-bottom: 1px solid var(--point);
    padding-top: 2rem;
    padding-bottom: 1rem;
}
.left-content .collapsible{
   cursor: pointer;
   font-size: 15px;
   line-height: 28px;
   color: #727272;

}
.angle{
    padding-top: 5px;
    padding-right: 2px;
}
.content-show{
    padding: 10px 20px 0 20px;
    font-size: 15px;
    color: #727272;
    font-weight: 400;
    line-height: 28px;
}
.right{
    border-radius: 10px;
    overflow: hidden;
    padding-left: 4rem;
}
.right img{
    width: 450px;
    height: 450px;
    border-radius: 10px;
}
@media screen and (max-width:1200px){
    .left{
        padding-right: 3rem;
        width: 50%;
    }
    .right{
        padding-left: 3rem;
    }
    .right img{
        width: 350px;
        height: 390px;
    }
    
}
@media screen and (max-width:968px){
    .accordion-row{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 5rem;
    }
    .left{
        padding: 0;
        width: 90%;
    }
    .right{
        padding: 3rem 0 0 0;

    }
    .right img{
        width: 500px;
    }

}
@media screen and (max-width:768px){
    .accordion{
        padding: 2rem 0;
        display: grid;
        place-items:center;
    }
    .accordion-row{
        padding: 0 2rem;
    }
    .right img{
        width: 400px;
    }
}
@media screen and (max-width:476px){
    .accordion-row{
        padding: 0 1rem;
    }
    .left h3{
        font-size: 2.3rem;
        font-weight: 400;
        margin-bottom: 1rem;
    }
    .right img{
        width: 350px;
    }
}
@media screen and (max-width:384px){
    .right img{
        width: 300px;
        height: 300px;
    }
    .left-content .collapsible{
        font-size: 14px;
    }

}
@media screen and (max-width:334px){
    .right img{
        width: 250px;
        height: 300px;
    }

}